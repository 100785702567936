import { css, Keyframes, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const HOLD_PERCENT = 10;
const FADE_DURATION = 10;
const MAX_OPACITY = 0.6;

const generateKeyframes = (steps: number): Keyframes[] => {
  const kf: Keyframes[] = [];
  for (let i = 0; i < steps; i++) {
    const changePercent = 1 + i * HOLD_PERCENT;
    const opacity = MAX_OPACITY - i / 10;
    kf.push(keyframes`
      0% { opacity: 0; }
      ${changePercent}% { opacity: 0; }  
      ${changePercent + FADE_DURATION}% { opacity: ${opacity}; } 
      ${80 - changePercent}% { opacity: ${opacity}; }  
      ${90 - changePercent}% { opacity: 0; }  
      100% { opacity: 0; }
    `);
  }
  return kf;
};

const Circle = styled.circle<{ duration: string; animation: Keyframes }>`
  opacity: 0;
  animation: ${({ animation }) => animation} ${({ duration }) => duration}s
    linear infinite;
`;

const circles = generateKeyframes(3);

const NewTabAnimation = ({ baseColor }: { baseColor: string }) => (
  <svg width="50" height="50" viewBox="0 0 50 50">
    <circle
      cx="25"
      cy="25"
      r="5"
      css={theme => css`
        fill: ${theme.colors[baseColor]};
      `}
    />
    {circles.map((kf, i) => (
      <Circle
        key={i}
        cx="25"
        cy="25"
        r={10 + i * 5}
        css={theme => css`
          fill: ${theme.colors[baseColor]};
        `}
        duration="5"
        animation={kf}
      />
    ))}
  </svg>
);

export default NewTabAnimation;
