import { PermanentShopType } from 'types/types';

export const getPermanentShops = (): PermanentShopType[] => [
  {
    id: 'extra-time-deals',
    name: 'Extra Time Deals',
    shortName: 'XTD',
    color: '',
    blurb: [],
  },
  {
    id: 'everyday-essentials',
    name: 'Everyday Essentials',
    shortName: 'Essentials',
    color: '',
    blurb: [],
  },
];
